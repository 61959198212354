<template>
  <van-popup 
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight: '35%', background: '#1E232D', padding: '0 10px' }"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @open="init"
    @close='closePop'
    round
  >

  <div class="input-wrap">
    <div class="input-title"> 
      <div class="title-left">{{ title }}</div>
      <div class="title-right" @click="handleConfirm">{{ $t('common.sure') }}</div>
    </div>

    <input v-if="inputType==='text'"
      v-model="message"
      :rows="rows"
      :type="inputType"
      :placeholder="placeholder"
      :maxlength="maxlength"
      autosize
      ref="inputObj"
    />

    <textarea v-if="inputType==='textarea'"
      v-model="message"
      :rows="rows"
      :placeholder="placeholder"
      :maxlength="maxlength"
      autosize
      ref="inputObj"
    />
  </div>

</van-popup>


</template>

<script>
export default {
  name:'',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    initMsg: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    maxlength: {
      type: Number,
      default: 30
    }
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    init() {
      console.log('init', this.initMsg)
      this.message = this.initMsg
    },
    closePop() {
      this.$emit('input', false)
    },
    handleConfirm() {
      this.$emit('getMessage', this.message)
      this.closePop()
    }
  },
  mounted() {
    this.init()
  },
  created() {
    this.$nextTick(()=> {
      this.$refs.inputObj?.focus()
    })
  }
}
</script>


<style lang="scss" scoped>
.input-wrap {
  margin: 20px 0;
  padding: 0 10px;

  .input-title {
    text-align: center;
    font-size: 20px;
    font-weight: blod;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .title-right {
      color: #FD2084;
    }
  }

  input,textarea {
    width: 100%;
    min-height: 30px;
    background: #14151E;
    padding-left: 20px;
    border-radius: 4px;
  }
  input {
    height: 40px;
  }
  textarea {
    padding-top: 10px;
  }
}

/deep/.van-field__control {
  padding-left: 5px;
  min-height: 40px;
}

.btn-groups {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .btn {
    width: 45%;
    height: 35px;
  }
  
}
.van-popup--round {
  border-radius: 10px 10px 0 0;
}
</style>