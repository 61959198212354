<template>
  <van-popup
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight: '35%', background: '#1E232D', padding: '0 10px' }"
    :transition-appear=true
    position="bottom"
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @close='closePop'
    round
  >

  <div class="picker-wrap">
    <div class="picker-title">
      <div class="title-left">{{ $t('userDetail.age') }}</div>
      <div class="title-right" @click="handleConfirm">{{ $t('common.sure') }}</div>  
    </div>
    <van-picker
      :title="$t('userDetail.age')"
      :columns="columns"
      ref="AgePicker"
    />
  </div>

</van-popup>


</template>

<script>
export default {
  name:'',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        {
          values: [18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99],
          defaultIndex: 2
        }
      ]
    }
  },
  methods: {
    closePop() {
      this.$emit('input', false)
    },
    handleConfirm() {
      let val = this.$refs.AgePicker.getValues()
      this.$emit('getSelected', val)
      this.closePop()
    }
  },
  mounted() {

  }
}
</script>


<style lang="scss" scoped>
.picker-wrap {
  margin: 20px 0;

  .picker-title {
    text-align: center;
    font-size: 20px;
    font-weight: blod;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .title-right {
      color: #FD2084;
    }
  }
}

/deep/.van-picker__columns {
  background: #1E232D;
}
/deep/.van-ellipsis {
  color: #fff;
}

/deep/.van-picker__mask {
  background-image: none;
}

.btn-groups {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .btn {
    width: 45%;
    height: 35px;
  }
}

.van-popup--round {
  border-radius: 5px 5px 0 0;
}

</style>