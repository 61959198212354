<template>
  <div class="changeAvatar" :style="{ backgroundColor: bgColor }">
    <div class="avatar-edit" :style="{ backgroundColor: bgColor === '#000000' ? '#14151e' : '#ffffff' }">
      <VanUploader ref="avatarUploader" :deletable="false" v-model="avatarList" :after-read="afterRead" :max-count="1"
        accept="image" />
      <div class="edit-btn">
        <input type="file" @change="handleModifyAvatar">
        <div class="icon" :style="{ backgroundColor: bgColor === '#000000' ? '#14151e' : '#ffffff' }">
          <img v-if="bgColor === '#000000'" src="@/assets/img/edit-avatar-icon-white.png" alt="">
          <img v-else src="@/assets/img/edit-avatar-icon-black.png" alt="">
        </div>
        <span class="require-flag">*</span>
        {{ $t('userDetail.editAvatar') }}
      </div>
    </div>
    <div class="cropper-box" v-show="showCropper">
      <div class="box">
        <vueCropper ref="cropper" :centerBox="option.centerBox" :autoCrop="option.autoCrop" :fixed="option.fixed"
          :img="option.img" :outputSize="option.outputSize" :canMoveBox="option.canMoveBox"
          :outputType="option.outputType">
        </vueCropper>
      </div>
      <div class="save-btn" @click="cropImage">更换头像</div>
    </div>
  </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'
import { uploadFile } from '@/service/upload'
import * as imageConversion from 'image-conversion';
export default {
  name: "changeAvatar",
  data() {
    return {
      avatarList: [],
      showCropper: false,
      option: {
        outputSize: 1,
        centerBox: true,
        autoCrop: true,
        fixed: true,
        canMoveBox: false,
        fixedBox: true,
        img: '',
        outputType: 'png',
      }
    };
  },
  components: { VueCropper },
  props: {
    headPhoto: {
      type: String,
      default: ""
    },
    setHeadPhoto: {
      type: Function,
      default: () => { }
    },
    bgColor: {
      type: String,
      default: ''
    },
  },
  watch: {
    headPhoto(val) {
      if (val) {
        this.avatarList = [{ url: this.$gbUtils.formatImgUrl(val) }]
      }
    },
  },
  methods: {
    handleModifyAvatar(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        this.option.img = base64String;
        this.showCropper = true;
      };
      reader.readAsDataURL(file);
    },
    async afterRead(e) {
      this.option.img = e.content;
      this.showCropper = true;
    },
    cropImage() {
      this.$refs.cropper.startCrop();
      this.$refs.cropper.getCropBlob(async (data) => {
        this.option.img = '';
        this.showCropper = false;
        this.$refs.cropper.stopCrop();
        const file = this.blobToFile(data, 'avatar.png');
        let compressFile = file;
        if (file.size / 1024 / 1024 > 0.3) {
          const res = await imageConversion.compress(file, 0.6);
          compressFile = new File([res], file.name, { type: 'image/png' });
        }
        const resp = await uploadFile(compressFile, 'image')
        if (resp.success) {
          this.setHeadPhoto(resp.s3Key)
          this.avatarList = [{ url: this.$gbUtils.formatImgUrl(resp.s3Key) }]
        }
      })
    },
    blobToFile(blob, fileName) {
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    }
  },
  created() { },
  mounted() { },
};
</script>


<style lang="scss" scoped>
.changeAvatar {
  padding: 3px 20px 10px 20px;
  padding-bottom: 10px;

  .avatar-edit {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;

    /deep/ .van-uploader__upload,
    /deep/ .van-uploader__preview {
      width: 92px;
      height: 92px;
      border-radius: 100%;
      margin-bottom: 0px;
      margin-left: 4px;
    }

    /deep/ .van-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }

    .edit-btn {
      width: 130px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 70px;
      font-weight: 600;
      font-size: 14px;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
      }

      .icon {
        border-radius: 100%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        img {
          width: 12px;
        }
      }

      .require-flag {
        color: red;
      }
    }
  }

  .cropper-box {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: #000;
    display: flex;
    flex-direction: column;

    .box {
      flex: 1;
      width: 100%;
    }

    /deep/.cropper-view-box {
      border-radius: 100%;
    }
  }

  .save-btn {
    width: 200px;
    height: 38px;
    background: #8A73D0;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    font-size: 13px;
    font-weight: 600;
    margin: 30px auto;
  }
}
</style>
